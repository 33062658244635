import { React, useState, useRef, useEffect } from "react";
import "./Articles.css";
import Grid from "@mui/material/Grid";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
import { ThemeProvider } from "@material-ui/styles";

import { darkTheme, lightTheme } from "../../components/themes";
import { HashLink } from "react-router-hash-link";

function ArticlesFooter(props) {
  const [appTheme, setAppTheme] = useState(
    props.theTheme === "lightTheme" ? lightTheme : darkTheme
  );
  init("user_bxfF5TvWm8i0NjmvGZUHZ");
  useEffect(() => {
    setAppTheme(props.theTheme === "lightTheme" ? lightTheme : darkTheme);
  }, [props.theTheme]);

  let social = [
    {
      header: "פייסבוק >",
      link: "https://www.facebook.com/profile.php?id=100091298461632",
    },
    {
      header: "אינסטגרם >",
      link: "https://www.instagram.com/ofirthefreelancer/",
    },
    // { header: "גיט >", link: "https://github.com/ofirthefreelancer/" },
    {
      header: "וואצאפ >",
      link: "https://api.whatsapp.com/send?phone=+972544626414&text=שלום (:",
    },
  ];
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    setError(false);
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
      e.preventDefault();
      if (name !== "" && lastName !== "" && email !== "" && message !== "") {
        emailjs
          .sendForm(
            "service_2exucqj",
            "template_xlzfapp",
            e.target,
            "user_bxfF5TvWm8i0NjmvGZUHZ"
          )
          .then(
            (result) => {
              setSubmitted(true);
            },
            (error) => {
              console.log(error.text);
            }
          );
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };
  return (
    <ThemeProvider theme={{ ...appTheme }}>
      <div style={appTheme.footerWrap}>
        <div>
          <Grid
            direction="row"
            alignItems="baseline"
            justifyContent="center"
            container
            spacing={8}
            style={{
              width: "100%",
              paddingTop: "50px",
              paddingBottom: "50px",
              fontSize: "1.3em",
            }}
          >
            {/* <Grid item xs={12} sm={12} md={4}>
                        <div style={appTheme.formWrap}>
                          <CssBaseline />

                          <div style={appTheme.paper}>
                            {!submitted && (
                              <Bounce>
                                <h1 style={{ marginTop: "0" }} id="lets-contact">
                                  בואו נדבר
                                </h1>
                                <form
                                  onSubmit={sendEmail}
                                  style={appTheme.form}
                                  noValidate
                                  ref={form}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        autoComplete="fname"
                                        name="from_name"
                                        variant="outlined"
                                        required
                                        onChange={(e) => setName(e.target.value)}
                                        fullWidth
                                        id="from_name"
                                        placeholder="שם פרטי"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="from_lastname"
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder="שם משפחה"
                                        name="from_lastname"
                                        autoComplete="lname"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        onChange={(e) => setEmail(e.target.value)}
                                        id="reply_to"
                                        placeholder="אימייל"
                                        name="reply_to"
                                        autoComplete="email"
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        placeholder="כתוב לי משהו..."
                                        id="message"
                                        multiline
                                        onChange={(e) => setMessage(e.target.value)}
                                        fullWidth
                                        name="message"
                                        minRows={10}
                                        maxRows={10}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Button
                                    type="submit"
                                    variant="outlined"
                                    color="secondary"
                                    style={appTheme.submit}
                                  >
                                    שלח
                                  </Button>
                                  {error && (
                                    <Typography style={{ marginTop: "15px" }}>
                                      *חלק מהפרטים חסרים
                                    </Typography>
                                  )}
                                </form>
                              </Bounce>
                            )}
                            {submitted && (
                              <div>
                                {" "}
                                <Typography variant="h5">
                                  תודה , הטופס נשלח בהצלחה.
                                </Typography>
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid> */}

            <Grid item xs={12} sm={4} md={3}>
              <div style={appTheme.socialLinks}>
                <strong> סושיאל </strong>
                <div id="social" style={appTheme.lastArticles}>
                  {social && social.length > 0
                    ? social.map((social) => {
                        return (
                          <div key={social.header}>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={social.link}
                              style={appTheme.lastArticlesItem}
                            >
                              {" "}
                              {social.header}
                            </a>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={5}>
              <div style={appTheme.lastArticles}>
                <strong> מאמרים אחרונים </strong>
                <div id="last-art" style={appTheme.lastArticles}>
                  {props.lastPosts && props.lastPosts.length > 0 ? (
                    props.lastPosts.map((post) => {
                      return (
                        <div key={post.id}>
                          <HashLink
                            style={appTheme.lastArticlesItem}
                            to={`/tutorials/post/${post.uniq_url}`}
                          >
                            {" "}
                            {post.postTitle}
                          </HashLink>
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <div style={appTheme.copyrights}>
            כל הזכויות שמורות © Ofir The Freelancer 2023{" "}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
export default ArticlesFooter;
