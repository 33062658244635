import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row style={{ alignItems: "center" }}>
            <Col md={1}></Col>
            <Col md={6} className="home-header">
              <h1 style={{ paddingBottom: 15 }}>
                שלום, נעים להכיר
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 style={{}}>
                קוראים לי
                <strong className="main-name"> אופיר.</strong>
              </h1>

              <div style={{}}>
                <Type className="typewriter" />
              </div>
            </Col>

            <Col md={4}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
            <Col md={1} style={{ display: "flex", justifyContent: "center" }}>
              <HashLink to="/blog" className="hash-link">
                <button className="actionBtn link">
                  <div>בלוג פיתוח אתרים</div>
                </button>
              </HashLink>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
