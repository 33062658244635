import { React, useState, useEffect, useRef } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme } from "../themes";
import Articles from "../Articles/Articles";
import ArticlesFooter from "../Articles/ArticlesFooter";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Blog() {
  const [lastPosts, setLastPosts] = useState([]);
  const isMounted = useRef(false); // Ref to track if the component is mounted

  const getPosts = async () => {
    try {
      let data = await axios.get("/api/getPosts");
      let postOutPutTemp = data.data.posts ? data.data.posts : [];

      postOutPutTemp = postOutPutTemp.sort((a, b) => b.postId - a.postId);
      if (isMounted.current) {
        setLastPosts(postOutPutTemp.slice(0, 5));
      }
    } catch {
      setTimeout(() => {
        console.log("server not found");
      }, 0);
    }
  };

  useEffect(() => {
    isMounted.current = true; // Set the ref to true when the component is mounted

    getPosts();

    return () => {
      isMounted.current = false; // Cleanup to set the ref to false when the component unmounts
    };
  }, []);

  const [appTheme] = useState(darkTheme);

  return (
    <ThemeProvider theme={{ appTheme }}>
      <Container fluid className="blog-section">
        <Particle />{" "}
        <div style={{ minHeight: "100vh" }}>
          <div id="scrollers">
            <div id="section-2" className="wrap">
              <Articles />
            </div>
            <div id="section-3">
              <ArticlesFooter lastPosts={lastPosts} />
            </div>
          </div>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default Blog;
