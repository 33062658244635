import { createMuiTheme } from "@mui/material/styles";
import { fontStyle } from "@mui/system";
export const pallete = {
  light: {
    quaternary: "#12232E",
    tertiary: "#007CC7",
    quinary: "#4DA8DA",
    secondary: "#203647",
    primary: "#EEFBFB",
  },
  dark: {
    quaternary: "#b0b0b0",
    tertiary: "#007CC7",
    quinary: "#4DA8DA",
    secondary: "#e2e2e2",
    primary: "#303030",
  },
};
export const specials = {
  light: {
    aboutBg: "#f9f9f9",
  },
};
export const lightTheme = createMuiTheme({
  spacing: 4,
  name: "lightTheme",
  cards: {
    bgColor: "#fff",
    headerColor: "initial",
  },
  mobileDrawer: {
    backgroundColor: pallete.light.primary,
  },
  headerWrapper: {
    backgroundColor: "rgb(249, 249, 249)",
  },
  ArticlesPostStamp: {
    fontStyle: "italic",

    paddingBottom: "10px",
  },
  ArticlesPostDesc: {
    color: "white",
    fontStyle: "italic",
  },
  ArticlesPostBody: {
    width: "100%",
  },
  footerWrap: {
    paddingTop: "50px",
    width: "100%",
  },
  drawerToolBar: {
    width: "100%",
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    backgroundColor: pallete.light.primary,
  },
  copyrights: {
    width: "100%",
    textAlign: "center",
    fontSize: "16px",
    marginTop: "30px",
  },
  form: {
    // paddingTop: "15px",
  },
  formWrap: {},
  lastArticlesItem: {
    color: "#fff",
  },
  submit: {
    marginTop: "20px",
    color: "black",
  },

  footerActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
  },
  postFooter: {
    width: "100%",
  },
  toolBar: {
    backgroundColor: pallete.light.primary,
    width: "100%",
  },

  ArticlesPostContent: {
    zIndex: "-1",
    paddingTop: "100px",
    flexDirection: "column",
    display: "flex",
    position: "absolute",
    justifyContent: "start",
    alignItems: "start",
  },
  Articles: {
    // minHeight: "100vh",
    margin: "0",
    fontSize: "20px",
    fontFamily: "OpenSansHebrew",
    color: "white",
    direction: "rtl",
    textAlign: "right",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    backgroundColor: pallete.light.primary,
  },
  postToolBar: {
    display: "flex",
    width: "100%",
  },
  top: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  regNews: {
    color: "black",
  },
  postHeader: {
    display: "flex",
    width: "100%",
  },
  arrowColor: {
    fill: "black",
    cursor: "pointer",
  },
  drawerText: {
    color: "black",
  },
  white: {
    color: "white",
  },
  ArticlesLink: {},
  ArticlesLinkDrawer: {},
  menuButton: {
    color: "black",
    fill: "black",
  },
  ArticlesTitle: {
    fontWeight: 800,
    paddingBottom: "20px",
  },
  bar: {
    color: "black",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  barItem: {
    color: "black",
    paddingRight: "7px",
    paddingLeft: "10px",
    fontSize: "24px",
  },
  aboutBg: "#f9f9f9",
  palette: {
    type: "light",
    primary: {
      main: "#00adff",
      contrastText: "#fff", //button text white instead of black
    },
    background: {
      //3b457d
      default: "#ffffff",
    },
  },

  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      label: {
        color: "#000",
      },
    },
  },
});

export const darkTheme = createMuiTheme({
  name: "darkTheme",
  socialLinks: {
    color: "#fff",
  },
  lastArticles: {
    color: "#fff",
  },

  cards: {
    bgColor: "#2c2c2c",
    headerColor: "#bbb",
  },

  headerWrapper: {
    backgroundColor: "transparent",
  },
  ArticlesPostDesc: {
    color: "white",
    fontStyle: "italic",
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#242424",
  },
  ArticlesPostBody: {
    width: "100%",
  },
  regNews: {
    color: "white",
  },
  cardText: {
    color: "white",
  },
  scrollTop: {
    color: "white",
  },
  ArticlesLink: {},
  ArticlesLinkDrawer: {},
  drawerToolBar: {
    width: "100%",
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
  },
  drawerText: {
    color: "white",
  },
  top: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuButton: {
    color: "white",
  },
  footerWrap: {
    width: "100%",
  },
  ArticlesTitle: {
    fontWeight: 800,
    paddingBottom: "20px",
    color: "#fafafa",
  },
  copyrights: {
    width: "100%",
    textAlign: "center",
    fontSize: "16px",
    marginTop: "30px",
    color: "#fff",
  },
  form: {
    // paddingTop: "15px",
  },
  formWrap: {},
  lastArticlesItem: {
    color: "#fff",
  },
  submit: {
    marginTop: "20px",
    color: "white",
  },
  footerActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
  },
  postFooter: {
    width: "100%",
  },
  palette: {
    type: "dark",
  },
  arrowColor: {
    fill: "white",
    cursor: "pointer",
  },
  ArticlesPostContent: {
    color: "white",
    zIndex: "-1",
    paddingTop: "100px",
    flexDirection: "column",
    display: "flex",
    position: "absolute",
    justifyContent: "start",
    alignItems: "start",
  },
  toolBar: {
    width: "100%",
    color: "white",
  },
  ArticlesPostHeader: {
    color: "#dedede",
  },
  ArticlesPostTitle: {
    color: "white",
  },
  ArticlesPostDesc: {
    color: "white",
  },
  ArticlesPostStamp: {
    color: "white",
    fontStyle: "italic",
    paddingBottom: "10px",
  },
  postToolBar: {
    display: "flex",
    width: "100%",
  },
  postHeader: {
    display: "flex",
    width: "100%",
  },
  bar: {
    color: "white",
    display: "flex",
  },
  Articles: {
    margin: "0",
    fontSize: "20px",
    fontFamily: "OpenSansHebrew",

    direction: "rtl",
    textAlign: "right",
  },
  barItem: {
    color: "white",
    paddingRight: "7px",
    paddingLeft: "10px",
    minHeight: "50px",
  },
  appBar: {
    position: "absolute",
    flexDirection: "row",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
      label: {
        color: "#fff",
      },
    },
  },
});
