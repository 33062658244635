import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo512.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { MdAccountTree } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row style={{ alignItems: "center" }}>
          <Col md={1}></Col>
          <Col md={6} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple"> היי </span> מה קורה?
            </h1>
            <p className="home-about-body">
              אני אופיר, מפתח פרונט-אנד, חי את עולם פיתוח האתרים ובין היתר כותב
              מאמרים הקשורים לעולם הWEB.
              <br /> פיתוח WEB הוא התשוקה שלי ואני בעל יכולת למידה גבוהה, פתרון
              בעיות מהיר, בעל עין חדה לפרטים הקטנים ויכולת עיצוב גבוהה.
            </p>

            <p className="home-about-body">
              הסטאק שלי : <br /> HTML5, CSS3, Javascript Vue, Angular, ReactJS,
              Tailwind, MaterialUI, Web3.js NodeJS, Express
            </p>
            <p className="home-about-body">שפות: עברית,אנגלית.</p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                style={{ width: "250px" }}
                src={myImg}
                className="img-fluid"
                alt="avatar"
              />
            </Tilt>
          </Col>
          <Col md={1}></Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>
              צרו <span className="purple">קשר </span>
            </h1>
            <p>
              הוסיפו <span className="purple">אותי </span> ברשתות
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/ofirthefreelancer"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/ofirthefreelancer/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ofirthefreelancer"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linktr.ee/ofirthefreelancer"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <MdAccountTree />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=100091298461632"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://wa.me/972544626414/?text=%D7%94%D7%99%D7%99%20%D7%90%D7%95%D7%A4%D7%99%D7%A8"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaWhatsapp />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
