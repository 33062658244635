import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import hljs from "highlight.js";
import "../../css/apprentice.css";
import "./ArticlesPost.css";
import ScrollToTop from "react-scroll-to-top";
import PostLoader from "./PostLoader";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import { HashLink } from "react-router-hash-link";
import $ from "jquery";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme, lightTheme, pallete } from "../themes";
import { makeStyles } from "@mui/styles";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ScrollIndicator from "./ScrollIndicator/ScrollIndicator";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    flexDirection: "row",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  returnIcon: {},
  hero: {
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "64px",
  },
  ArticlesTitle: {
    fontWeight: 800,
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: 240,
  },
  cardActions: {
    display: "flex",
    margin: "0 10px",
    justifyContent: "space-between",
  },
  h4: {
    color: "black",
  },
  arrow: {
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  top: {
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topItems: {
    display: "flex",
    alignItems: "center",
  },
  barItem: {
    paddingLeft: "15px",
  },
  bar: {
    color: "black",
    display: "flex",
  },
  monster: {
    padding: "10px",
  },
  author: {
    display: "flex",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

function ArticlesPost(props) {
  hljs.configure({ ignoreUnescapedHTML: true });
  hljs.configure({ useBR: true });
  const [theme, setTheme] = useState("light");
  const [appTheme, setAppTheme] = useState(lightTheme);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  function navigateToArticles(id) {
    history(`/blog`);
  }

  const updateCodeSyntaxHighlighting = () => {
    document.querySelectorAll("code").forEach((block) => {
      hljs.highlightElement(block);
    });
  };

  const { id } = useParams();
  const isMounted = useRef(false);
  let [postData, setPostData] = useState({});

  const fetchArticlesPost = async () => {
    if (isMounted.current) {
      try {
        const res = await axios.get(`/api/fetchArticlesPost/${id}`);
        if (res && res.data && res.data.post) {
          let postData = res.data.post;

          if (Object.keys(postData).length > 0) {
            // Replace \n with <br/> in postBody content
            postData.postBody = postData.postBody.replace(/\n/g, "<br/>");

            // Merge multiple <code> blocks inside <pre> into one <code> block
            postData.postBody = mergeCodeBlocksInPre(postData.postBody);

            setPostData({ ...postData });

            // Delay to ensure content is rendered before applying syntax highlighting
            setTimeout(() => {
              updateCodeSyntaxHighlighting(); // Apply syntax highlighting
              addCopyButtonsToCode(); // Add copy buttons after rendering
            }, 0);

            setLoading(false);
          } else {
            navigateToArticles(); // Navigate if no post data is found
          }
        } else {
          navigateToArticles(); // Navigate if response is empty
        }
      } catch (err) {
        setLoading(false);
        console.error("Error fetching article post:", err);
        navigateToArticles(); // Navigate on error
      }
    }
  };

  // Function to merge multiple <code> blocks inside a <pre> into one <code> block
  function mergeCodeBlocksInPre(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const preElements = doc.querySelectorAll("pre");

    preElements.forEach((pre) => {
      const codeElements = pre.querySelectorAll("code");

      if (codeElements.length > 1) {
        let combinedCodeContent = "";
        codeElements.forEach((code) => {
          combinedCodeContent += code.innerHTML + "\n";
        });

        const newCodeElement = document.createElement("code");
        newCodeElement.innerHTML = combinedCodeContent;

        codeElements.forEach((code) => code.remove());

        pre.appendChild(newCodeElement);
      }
    });

    return doc.body.innerHTML;
  }

  // Function to copy text to the clipboard and change SVG color to green when clicked
  function copyToClipboard(text, copyButton) {
    const tempTextArea = document.createElement("textarea");
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextArea);

    // Change the SVG fill color to green when clicked
    const svgElement = copyButton.querySelector("svg");
    if (svgElement) {
      svgElement.style.fill = "green";
    }

    // Optional: Revert the color back to the original after a delay (e.g., 2 seconds)
    setTimeout(() => {
      if (svgElement) {
        svgElement.style.fill = ""; // Reset to original color
      }
    }, 1000);
  }

  // Function to add copy button to each <code> block
  function addCopyButtonsToCode() {
    const codeElements = document.querySelectorAll("code");

    codeElements.forEach((code) => {
      const copyButton = document.createElement("button");
      copyButton.classList.add("copy-button");
      copyButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
      </svg>
    `;

      // Add click event to copy the content of the <code> block and change SVG color
      copyButton.addEventListener("click", () => {
        const codeContent = code.innerText;
        copyToClipboard(codeContent, copyButton); // Pass the copyButton as a parameter
      });

      code.appendChild(copyButton);
      code.style.position = "relative";
    });
  }

  useEffect(() => {
    isMounted.current = true;
    fetchArticlesPost();

    // this is run when component unmounts
    return () => (isMounted.current = false);
  }, [theme]);

  const classes = useStyles();

  return postData && postData.id && !loading ? (
    <ThemeProvider theme={{ ...appTheme }}>
      <Container fluid className="post-section">
        <Particle />
        <div style={appTheme.Articles}>
          <div className="postArticle">
            <div className="ArticlesPostContent">
              <div>
                <div>
                  <a
                    href="/blog"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      textDecoration: "none",
                      padding: "0",
                    }}
                  >
                    <ExitToAppIcon
                      style={{
                        marginBottom: "10px",
                        marginRight: "-4px",
                        color: "white",
                        fontSize: "40px", // Increase arrow size
                      }}
                      className="backBtn"
                    />{" "}
                    {/* White back arrow */}
                  </a>
                </div>
                <h1 style={appTheme.ArticlesPostHeader}>
                  {postData.postTitle}
                </h1>
              </div>
              <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                <h4 style={appTheme.ArticlesPostDesc}>{postData.postDesc}</h4>
              </div>
              <div
                style={{
                  color: "white",
                  fontStyle: "italic",
                  paddingBottom: "25px",
                }}
              >
                {postData.postTimestamp}
              </div>

              <div
                style={appTheme.ArticlesPostBody}
                dangerouslySetInnerHTML={{ __html: postData.postBody }}
              ></div>
              <div>
                <div style={{ paddingTop: "10px", fontStyle: "italic" }}>
                  <h4>
                    <span>
                      במידה ואתם רוצים להתייעץ איתי לגבי כל נושא ללא עלות -{" "}
                    </span>
                    <HashLink to="/#contact_section">
                      <span
                        style={{
                          color: "#009c9c",
                          textDecoration: "underline",
                        }}
                      >
                        שלחו לי הודעה{" "}
                      </span>
                    </HashLink>
                  </h4>
                  <h4>אשמח אם תשתפו (:</h4>
                  <div></div>
                  <div style={appTheme.footerActions}>
                    <div>
                      <Hidden xsDown>
                        <HashLink to="#top" style={appTheme.scrollTop}>
                          חזרה למעלה
                        </HashLink>
                      </Hidden>
                      <Hidden smUp>
                        <br />
                        <ScrollToTop smooth color="#6f00ff" top="400" />
                      </Hidden>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </Container>
    </ThemeProvider>
  ) : (
    <div className="post-load">
      <div className="load-wrap">
        <PostLoader />
      </div>
    </div>
  );
}

export default ArticlesPost;
