import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import pacifico from "../../Assets/Projects/1.png";
import med from "../../Assets/Projects/2.png";
import space from "../../Assets/Projects/3.png";
import tech from "../../Assets/Projects/tech.png";

function Projects() {
  return (
    <Container
      fluid
      className="blog-section"
      style={{ paddingTop: "100px", minHeight: "100vh" }}
    >
      <Particle />

      <h1 className="project-heading">
        פרוייקטים <strong className="purple">אחרונים </strong>
      </h1>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          paddingBottom: "10px",
        }}
      >
        <Col style={{ textAlign: "center" }} md={4} className="project-card">
          <ProjectCard
            imgPath={space}
            isBlog={false}
            title="The Space Between"
            description="A website built for an israeli rock band The Space Between"
            ghLink="https://github.com/ofirthefreelancer/thespacebetween"
            demoLink="https://thespacebetween-bd263fd9024e.herokuapp.com/"
          />
        </Col>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={med}
            isBlog={false}
            title="The Mediterenian Bianale"
            description="A Website built for the medditerenian bianale"
            ghLink="https://github.com/ofirthefreelancer/medbiennale"
            demoLink="https://med-bianale-f5c27f40101c.herokuapp.com/"
          />
        </Col>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={pacifico}
            isBlog={false}
            title="Pacifico El Paredon"
            description="A Website built for an hostel Pacifico Paredon in Guatemala"
            ghLink="https://github.com/ofirthefreelancer/pacificoelparedon"
            demoLink="https://pacificoelparedon-63be7fac5487.herokuapp.com/"
          />
        </Col>
      </Row>
      <Row
        style={{
          textAlign: "center",
          justifyContent: "center",
          paddingBottom: "10px",
        }}
      >
        <Col style={{ textAlign: "center" }} md={4} className="project-card">
          <ProjectCard
            imgPath={tech}
            isBlog={false}
            title="Tech-Yourself"
            description="Tech Yourself is a comprehensive assessment tool designed for web developers to evaluate their skills across essential technologies like HTML, CSS, JavaScript, and more."
            ghLink="https://github.com/ofirthefreelancer/tech-yourself"
            demoLink="https://tech-yourself-ca195c00072a.herokuapp.com/"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Projects;
