import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            יש לי את הידע והכלים לתת לכם שירות ומוצר בצורה הטובה ביותר. יש לי
            תשוקה למה שאני עושה ואני אוהב את העבודה שלי, ויותר מאשמח לתת לכם
            שירות ברמה גבוהה.
          </p>
          <p style={{ textAlign: "justify" }}>
            אני מתכנת פרונט-אנד עם 10 שנות נסיון. התחלתי לתכנת בגיל 13 , ובמהרה
            הבנתי שזה מה שאני אוהב לעשות. מצאתי את עצמי מתעסק בתכנות בשעות
            הפנאי, במיוחד בכל הקשור לפיתוח אתרים. יש לי נסיון בפיתוח, קידום,
            אבטחה, וגם בעיצוב. אם אתם צריכים לבנות אתר חדש, דפי נחיתה, או שיש
            לכם אתר שאתם צריכים לתחזק אותו - הרגישו חופשי לפנות אליי. אני גם
            מייעץ לאנשים בתחילת דרכם שרוצים להיכנס לתחום ולא יודעים איך.
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "הסוד להתקדמות הוא להתחיל."{" "}
          </p>
          <footer className="blockquote-footer">מארק טוויין</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
