import { React, useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import $ from "jquery";

import Card from "@mui/material/Card";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme, lightTheme, pallete } from "../../components/themes";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import Header from "../Navbar";

const useStyles = makeStyles((theme) => ({
  returnIcon: {},
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./banner.jpg)`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "60px",
  },
  authDetail: {
    paddingRight: "10px",
  },
  ArticlesContainer: {},

  media: {
    height: 240,
  },
  cardActions: {
    display: "flex",
    margin: "0 10px",
    justifyContent: "space-between",
  },
  h4: {
    color: "black",
  },
  arrow: {
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  card: {
    maxWidth: "100%",
    margin: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  barItem: {
    paddingLeft: "15px",
  },
  submit: {
    marginTop: "20px",
  },
  bar: {
    color: "black",
    display: "flex",
  },
  monster: {
    padding: "10px",
  },
  author: {
    display: "flex",
    paddingLeft: "10px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
  },
  topItems: {
    display: "flex",
    alignItems: "center",
  },
}));

let lastPosts = [];

function Articles(props) {
  const catsRef = useRef(null);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [loadCatPosts, setLoadCatPosts] = useState(false);
  const [postClone, setPostClone] = useState([]);
  const [allPostsLen, setallPostsLen] = useState(0);
  const [postOutPut, setPostOutput] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("allCats");
  const [categoriesOutput, setCategoriesOutput] = useState([]);
  const [appTheme] = useState(darkTheme);
  const [isMount, setIsMount] = useState(true);

  function getPostCategory(postId) {
    // Loop through each category to find the post
    for (let category of categoriesOutput) {
      // Check if the postId exists in the category's posts
      if (category.posts.includes(postId.toString())) {
        return category.catName; // Return the category name if found
      }
    }

    // If the postId is not found in any category, return a default value
    return "Uncategorized";
  }

  function filterCategories(category) {
    setLoadCatPosts(true);
    setSelectedCategory(category.id);
    filterPosts(category);
  }

  function filterPosts(cats) {
    let tempPosts = [];
    for (let key in postClone) {
      if (cats.posts.indexOf(postClone[key].postId.toString()) !== -1) {
        tempPosts.push(postClone[key]);
      }
    }
    setPostOutput(tempPosts);
    setTimeout(() => {
      setLoadCatPosts(false);
    }, 0);
  }

  // Helper function to get only visible posts
  const getVisiblePosts = async () => {
    try {
      let data = await axios.get("/api/getPosts");
      let posts = data.data.posts ? data.data.posts : [];

      // Filter posts where visible is true
      return posts.filter((post) => post.visible);
    } catch {
      console.log("server not found");
      return [];
    }
  };

  const getCategories = async () => {
    try {
      let visiblePosts = await getVisiblePosts(); // Get only visible posts
      let visiblePostIds = visiblePosts.map((post) => post.postId.toString()); // Convert postId to string for comparison

      let data = await axios.get("/api/getCategories");
      let catsOutputTemp = data.data.categories;

      // Filter categories to include only those with visible posts
      catsOutputTemp = catsOutputTemp.map((category) => {
        const filteredPosts = category.posts.filter((postId) =>
          visiblePostIds.includes(postId)
        );

        return {
          ...category,
          posts: filteredPosts,
          volume: filteredPosts.length, // Update the volume to reflect the number of visible posts
        };
      });

      // Filter out categories that have no visible posts
      catsOutputTemp = catsOutputTemp.filter((category) => category.volume > 0);

      setCategoriesOutput(catsOutputTemp);
      setPostClone(visiblePosts);
      setallPostsLen(visiblePosts.length); // Update the total length to visible posts only

      // Set the initially loaded posts to display them
      setPostOutput(visiblePosts); // <-- This ensures posts are displayed on initial load
    } catch {
      console.log("server not found");
      setTimeout(() => {
        setLoadingArticles(false);
      }, 0);
    }
  };

  async function getInfo() {
    setLoadingArticles(true);
    if (isMount) {
      // Get posts and categories collection
      await getCategories();

      // Wait for the categories to load
      setTimeout(() => {
        setLoadingArticles(false);
      }, 0);
    }
  }

  useEffect(() => {
    $("html").css("background-color", pallete.light.primary);
    getInfo();
    return () => setIsMount(false);
  }, []);

  const classes = useStyles();
  return (
    <ThemeProvider theme={{ appTheme }}>
      <h1 style={{ fontSize: "2.1em", textAlign: "right", color: "white" }}>
        בלוג <span className="purple"> פיתוח אתרים </span>
      </h1>
      <div style={appTheme.Articles}>
        <span
          id="last-articles"
          style={{ height: "20px" }}
          ref={catsRef}
        ></span>

        <Container
          id="last-articles-ref"
          style={{ padding: "0px", maxWidth: "1500px" }}
        >
          {loadingArticles ? (
            <div id="loadCats" style={{ minHeight: "70vh" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ color: "#fff" }}>טוען...</div>
              </div>
            </div>
          ) : (
            <div style={{ paddingTop: "40px" }}>
              <div className="categories tags">
                <div
                  key="allCats"
                  onClick={() => {
                    setLoadCatPosts(true);
                    setSelectedCategory("allCats");
                    setPostOutput(postClone);
                    setTimeout(() => {
                      setLoadCatPosts(false);
                    }, 0);
                  }}
                  className={
                    selectedCategory === "allCats" ? "selectedCat" : ""
                  }
                >
                  הכל ({allPostsLen})
                </div>
                {categoriesOutput.length > 0
                  ? categoriesOutput.map((cat) => {
                      return (
                        <div
                          key={cat.id}
                          onClick={() => {
                            filterCategories(cat);
                          }}
                          className={
                            cat.id === selectedCategory ? "selectedCat" : ""
                          }
                        >
                          {cat.catName} ({cat.volume})
                        </div>
                      );
                    })
                  : ""}
              </div>
              {postOutPut.length > 0 ? (
                <Grid
                  style={{ width: "100%" }}
                  direction="row"
                  alignItems="stretch"
                  container
                  spacing={3}
                >
                  {loadCatPosts ? (
                    <div id="loadCats">
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  ) : (
                    postOutPut.map((post) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          key={post.postId}
                          style={{ paddingBottom: "10px" }}
                        >
                          <Card
                            className="muiCard shadow"
                            key={post.postId}
                            style={appTheme.card}
                          >
                            <a
                              className="card-link"
                              href={`/tutorials/post/${post.uniq_url}`}
                            >
                              <CardActionArea>
                                <CardMedia
                                  className={classes.media}
                                  image={require(`../../img/blog/${post.postImg}`)}
                                ></CardMedia>
                                <div className="cat-container">
                                  <div className="cat-wrap">
                                    <span>{getPostCategory(post.postId)}</span>
                                  </div>
                                </div>
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h5"
                                    style={{
                                      color: "white",
                                      textDecoration: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {post.postTitle}
                                  </Typography>
                                  <Typography
                                    style={{
                                      color: "white",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {post.postDesc}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </a>

                            <CardActions className={classes.cardActions}>
                              <Box className={classes.author}>
                                <Avatar src={post.authAvatar} />
                                <Box ml={2} className={classes.authDetail}>
                                  <div className="author" component="p">
                                    <span style={appTheme.cardText}>
                                      {post.postAuthor}
                                    </span>
                                  </div>
                                  <div className="timestamp" component="p">
                                    <span style={appTheme.cardText}>
                                      {post.postTimestamp}
                                    </span>
                                  </div>
                                </Box>
                              </Box>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })
                  )}
                </Grid>
              ) : (
                <div>אין פוסטים להצגה</div>
              )}
            </div>
          )}
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default Articles;
