import React, { useEffect, useRef } from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        ימים של <strong className="purple"> קוד</strong>
      </h1>
      {isMounted.current && (
        <GitHubCalendar
          username="soumyajit4419"
          blockSize={15}
          blockMargin={5}
          color="#c084f5"
          fontSize={16}
        />
      )}
    </Row>
  );
}

export default Github;
